import {
    Component,
    Input,
    ViewChild
} from '@angular/core';
import { Submission } from 'src/app/components/dashboard/models/dashboard.models';
import { DashboardState } from 'src/app/components/dashboard/store/dashboard.state';
import { Select, Store } from '@ngxs/store';
import { DashboardTab } from 'src/app/components/dashboard/store/dashboard.state.models';
import { Observable } from 'rxjs';
import { PageSize, PageSizes } from 'src/app/components/shared/components/grid/models/page-size';
import { SraSelectItem } from 'src/app/common/models/sra-select-item';
import { DxoGridComponent } from 'devextreme-angular/ui/nested';
import { SubmissionsDataService } from 'src/app/components/dashboard/services/submissions-data.service';
import * as DashboardActions from 'src/app/components/dashboard/store/dashboard.actions';

@Component({
    selector: 'srdi-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.less'],
})
export class GridComponent {
    constructor(
        private readonly submissionsDataService: SubmissionsDataService,
        private readonly store: Store,
    ) {
        this.submissionsCount$.subscribe(submissionsCount => {
            this.rowsCount = submissionsCount[this.store.selectSnapshot(DashboardState.activeTab)];
        });
    }

    @Select(DashboardState.activeTab)
    public activeTab$: Observable<DashboardTab>;
    
    @Select(DashboardState.isLoading)
    public isLoading$: Observable<boolean>;

    @Select(DashboardState.submissionsCount)
    public submissionsCount$: Observable<any>;
    
    @Input()
    public data: Submission[] | null;

    @ViewChild('grid')
    public dxGrid: DxoGridComponent;

    public pageSize: PageSize = PageSize.Ten;
    public pageSizes: SraSelectItem[] = PageSizes;
    public currentPageIndex: number = 0;
    public rowsCount: number;

    public handlePrevPage(): void {
        const currentIndex = this.currentPageIndex;

        this.dxGrid.instance.pageIndex(currentIndex - 1);
        this.currentPageIndex = currentIndex - 1;

        this.submissionsDataService.pageIndex = this.currentPageIndex;
        this.store.dispatch(new DashboardActions.LoadSubmissionsData());
    }

    public handleNextPage(): void {
        const currentIndex = this.currentPageIndex;

        this.dxGrid.instance.pageIndex(currentIndex + 1);
        this.currentPageIndex = currentIndex + 1;
        this.submissionsDataService.pageIndex = this.currentPageIndex;
        this.store.dispatch(new DashboardActions.LoadSubmissionsData());
    }

    public handleChangePageSize(pageSize: PageSize): void {
        this.pageSize = pageSize;
        this.currentPageIndex = 0;
        this.dxGrid.instance.pageIndex(0);
        this.submissionsDataService.pageSize = pageSize;
        this.submissionsDataService.pageIndex = 0;
        this.store.dispatch(new DashboardActions.LoadSubmissionsData());
    }

    public optionChangedHandler(event: any): void {
        if (event.fullName === 'searchPanel.text') {
            this.submissionsDataService.pageIndex = 0;
            this.submissionsDataService.searchText = event.value;
            this.store.dispatch(new DashboardActions.LoadSubmissionsData());
            this.currentPageIndex = 0;
            return;
        }

        if (event.fullName === 'columns') {
            this.currentPageIndex = 0;
            this.submissionsDataService.pageIndex = 0;
        }

        const match = (event.fullName as string).match(/^columns\[(\d+)\]\.sortOrder$/);
        if (match) {
            this.submissionsDataService.sortBy = +match[1];
            this.submissionsDataService.sortOrder = event.value;
            this.submissionsDataService.pageIndex = 0;
            this.store.dispatch(new DashboardActions.LoadSubmissionsData());
        }
    }
}
