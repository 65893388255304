import { ToastComponent } from 'src/app/components/ui-interact/components/toast/toast.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { UiInteractState } from 'src/app/components/ui-interact/store/ui-interact.state';

const DECLARATIONS = [
    ToastComponent,
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxsModule.forFeature([UiInteractState]),
    ],
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiInteractModule { }
