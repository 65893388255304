import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ToastConfig, UiInteractStateModel } from 'src/app/components/ui-interact/store/ui-interact.state.models';
import * as UiInteractActions from 'src/app/components/ui-interact/store/ui-interact.actions';

@State<UiInteractStateModel>({
    name: 'uiInteract',
    defaults: new UiInteractStateModel(),
})
@Injectable()
export class UiInteractState {
    @Selector()
    public static toastConfig(state: UiInteractStateModel): ToastConfig {
        return state.toastConfig;
    }
    
    @Action(UiInteractActions.SetToastConfig)
    public setToastConfig(
        ctx: StateContext<UiInteractStateModel>,
        { toastConfig }: UiInteractActions.SetToastConfig,
    ): void {
        ctx.patchState({ toastConfig });
    }
}
