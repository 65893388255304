import { Initializable } from 'src/app/common/helpers/initializable';
import { ToastConfig } from 'src/app/components/ui-interact/store/ui-interact.state.models';

const source = '[UiInteractState]';

export class SetToastConfig extends Initializable<SetToastConfig> {
    public static type: string = `${source} set toast config`;

    public toastConfig: ToastConfig;
}
