import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/configuration/app-config';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    private readonly bootstrapPromise: Promise<void>;
    private resolve: () => void;

    public constructor(private readonly appConfig: AppConfig) {
        this.bootstrapPromise = new Promise<void>(resolve => this.resolve = resolve);
    }

    public init(): Promise<void> {
        this.setAppConfig().then(() => this.resolve());
        
        return this.bootstrapPromise;
    }
    
    private setAppConfig(): Promise<void> {
        return Promise.all([])
            .then(() => {
                const config = {};

                Object.assign(this.appConfig, config);
            });
    }
}