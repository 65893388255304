import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from 'src/app/components/root/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { Environment } from 'src/environment/environment';
import { DashboardModule } from 'src/app/components/dashboard/dashboard.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { defineCustomElements } from '@ui-ds-component-lib/core/loader';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { UiInteractModule } from 'src/app/components/ui-interact/ui-interact.module';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AppInitService } from 'src/app/configuration/app-init.service';
import { AppConfig } from 'src/app/configuration/app-config';

defineCustomElements();

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot(),
        NgxsModule.forRoot([], { 
            developmentMode: !Environment.production,
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false,
            },
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: Environment.production }),
        NgxsLoggerPluginModule.forRoot({ disabled: Environment.production }),
        SharedModule,
        DashboardModule,
        UiInteractModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: Environment.clientId,
                    authority: 'https://login.microsoftonline.com/45597f60-6e37-4be7-acfb-4c9e23b261ea',
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                    navigateToLoginRequestUrl: true,

                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
                system: {
                    allowNativeBroker: false,
                },
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: [Environment.apiProviderScope],
                },
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    [Environment.getSubmissions, [Environment.apiProviderScope]],
                    [`${Environment.msGraph}/me`, ['user.read']],
                ]),
            },
        ),
    ],
    providers: [
        MsalGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitService: AppInitService) => appInitService.init.bind(appInitService),
            deps: [AppInitService],
            multi: true,
        },
        {
            provide: AppConfig, useValue: AppConfig.Instance,
        }],
    bootstrap: [AppComponent, MsalRedirectComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
