import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubHeaderComponent } from 'src/app/components/shared/components/sub-header/sub-header.component';
import { CoreAngularModule } from '@ui-ds-component-lib/core-angular';
import { GridComponent } from 'src/app/components/shared/components/grid/components/root/grid.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { CustomPagingComponent } from 'src/app/components/shared/components/grid/components/custom-paging/custom-paging.component';
import { LoaderComponent } from 'src/app/components/shared/components/loader/loader.component';
import { DxLoadIndicatorModule } from 'devextreme-angular';
import { HeaderComponent } from 'src/app/components/shared/components/header/components/root/header.component';
import { NgxsModule } from '@ngxs/store';
import { HeaderState } from 'src/app/components/shared/components/header/store/header.state';
import { AvatarFallbackComponent } from 'src/app/components/shared/components/header/components/avatar-fallback/avatar-fallback.component';

const DECLARATIONS = [
    SubHeaderComponent,
    GridComponent,
    CustomPagingComponent,
    LoaderComponent,
    HeaderComponent,
    AvatarFallbackComponent,
];
const SHARED_MODULES = [
    CoreAngularModule,
    DxButtonModule,
    DxDataGridModule,
    DxLoadIndicatorModule,
];

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature([HeaderState]),
        ...SHARED_MODULES,
    ],
    declarations: DECLARATIONS,
    exports: [...DECLARATIONS, ...SHARED_MODULES],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }