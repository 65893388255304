<div class="srdi-custom-paging">
    <sra-icon-button class="srdi-custom-paging__left-button" icon="expand_circle_down" mini="true"
                     [disabled]="isPrevButtonDisabled"
                     (click)="prevPage.emit()"
    ></sra-icon-button>
    <span>{{ pagingLabel }}</span>
    <sra-icon-button class="srdi-custom-paging__right-button" icon="expand_circle_down" mini="true"
                     [disabled]="isNextButtonDisabled"
                     (click)="nextPage.emit()"
    ></sra-icon-button>
    <sra-select (change)="onPageSizeChange($event)">
        <sra-select-item *ngFor="let pageSize of pageSizes"
                         [value]="pageSize.value"
                         [selected]="pageSize.value === selectedPageSize"
        >{{ pageSize.label }}</sra-select-item>
    </sra-select>
</div>
