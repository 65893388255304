import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from 'src/app/components/dashboard/components/root/dashboard.component';
import { NgxsModule } from '@ngxs/store';
import { DashboardState } from 'src/app/components/dashboard/store/dashboard.state';
import { SharedModule } from 'src/app/components/shared/shared.module';

const DECLARATIONS = [
    DashboardComponent,
];

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature([DashboardState]),
        SharedModule,
    ],
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule { }