import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UiInteractState } from 'src/app/components/ui-interact/store/ui-interact.state';
import { ToastConfig } from 'src/app/components/ui-interact/store/ui-interact.state.models';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'srdi-toast',
    templateUrl: './toast.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit {
    constructor(private readonly cdRef: ChangeDetectorRef) { }

    @Select(UiInteractState.toastConfig)
    public toastConfig$: Observable<ToastConfig>;

    public shouldShowToast: boolean = false;
    public toastConfig: ToastConfig;

    private readonly destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.toastConfig$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(config => {
                this.shouldShowToast = true;
                this.toastConfig = config;

                this.cdRef.detectChanges();
            });
    }
}
