import { Injectable } from '@angular/core';
import { from, map, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { blobToBase64 } from 'src/app/common/helpers/blod-to-base64';
import { Environment } from 'src/environment/environment';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    constructor(private readonly httpClient: HttpClient) { }

    public getUserAvatar(): Observable<string> {
        return this.httpClient.get(`${Environment.msGraph}/me/photo/$value`, { responseType: 'blob' })
            .pipe(
                switchMap(response => from(blobToBase64(response))),
            );
    }

    public getUserInfo(): Observable<string> {
        return this.httpClient.get(`${Environment.msGraph}/me`)
            .pipe(
                map((response: any) => `${response.givenName[0]}${response.surname[0]}`),
            );
    }
}