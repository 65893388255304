import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutePath } from 'src/app/route-path';
import { DashboardComponent } from 'src/app/components/dashboard/components/root/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    { path: '', redirectTo: RoutePath.Dashboard, pathMatch: 'full' },
    { path: RoutePath.Dashboard, component: DashboardComponent, canActivate: [MsalGuard] },
    { path: '**', redirectTo: RoutePath.Dashboard },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
