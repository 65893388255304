import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs';
import { Submission } from 'src/app/components/dashboard/models/dashboard.models';
import { StringHelper } from 'src/app/common/helpers/string';
import { Environment } from 'src/environment/environment';
import { DashboardTab } from 'src/app/components/dashboard/store/dashboard.state.models';
import { PageSize } from 'src/app/components/shared/components/grid/models/page-size';

@Injectable({
    providedIn: 'root',
})
export class SubmissionsDataService {
    private readonly columns: string[] = ['emailSubject', 'sender', 'received', 'status', 'submissionId', 'submissionUrl', 'transactionId'];
    private readonly tabMap: any = {'In Progress': 'active', 'Completed': 'completed'};
    private _pageSize: PageSize = PageSize.Ten;
    private _pageIndex: number = 0;
    private _sortBy: number = 2;
    private _sortOrder: string = 'desc';
    private _searchText: string = '';
    private _activeTab: DashboardTab = DashboardTab.InProgress;

    constructor(private readonly http: HttpClient) { }

    public set pageSize(pageSize: PageSize) {
        this._pageSize = pageSize;
    }

    public set pageIndex(pageIndex: number) {
        this._pageIndex = pageIndex;
    }

    public set sortBy(sortBy: number) {
        this._sortBy = sortBy;
    }

    public set sortOrder(sortOrder: string) {
        this._sortOrder = sortOrder;
    }

    public set searchText(searchText: string) {
        this._searchText = searchText;
    }

    public set activeTab(activeTab: DashboardTab) {
        this._activeTab = activeTab;
    }

    public getSubmissionsData(): any {
        return this.http.get(Environment.getSubmissions, { params: this.createSubmissionQueryParameters() })
        //return of(submissionsDataMock) //uncomment to use mocked data
            .pipe(
                map(data => this.convertSubmissionsData(data)),
            );
    }

    private convertSubmissionsData(data: any): any {
        const convertedData = { ...data };
        convertedData.submissions = data.submissions.map((submission: any) => {
            return new Submission({
                subject: submission.emailSubject || '-',
                receiveDate: submission.received ? new Date(submission.received) : '-',
                submissionId: submission.submissionId || '-',
                status: submission.status || '-',
                sender: submission.sender ? StringHelper.getSenderFromEmail(submission.sender) : '-',
                submissionUrl: submission.submissionUrl,
                transactionId: submission.transactionId || '-',
                transactionUrl: submission.transactionUrl,
            });
        });
        return convertedData;
    }

    private createSubmissionQueryParameters(): HttpParams {
        var params = new HttpParams()
            .set('page', this._pageIndex)
            .set('pageSize', this._pageSize)
            .set('sortBy', this.columns[this._sortBy])
            .set('sortOrder', this._sortOrder)
            .set('status', this.tabMap[this._activeTab]);

        if (this._searchText && this._searchText.trim() !== '') {
            params = params
                .set('searchTerm', 'subject')
                .set('searchValue', this._searchText.trim());
        }
        return params;
    }
}