import { Initializable } from 'src/app/common/helpers/initializable';
import { DashboardTab } from 'src/app/components/dashboard/store/dashboard.state.models';
import { Submission } from 'src/app/components/dashboard/models/dashboard.models';

const source = '[DashboardState]';

export class SelectDashboardTab extends Initializable<SelectDashboardTab> {
    public static type: string = `${source} select dashboard tab`;

    public selectedTab: DashboardTab;
}

export class LoadSubmissionsData extends Initializable<LoadSubmissionsData> {
    public static type: string = `${source} load submissions data`;
}

export class LoadSubmissionsDataSuccess extends Initializable<LoadSubmissionsDataSuccess> {
    public static type: string = `${source} load submissions data success`;

    public submissions: Submission[];
    public submissionsCount: number;
}

export class LoadSubmissionsDataError extends Initializable<LoadSubmissionsDataError> {
    public static type: string = `${source} load submissions data error`;

    public error: any;
}