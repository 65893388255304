import { SraSelectItem } from 'src/app/common/models/sra-select-item';

export enum PageSize {
    Ten = '10',
    Twenty = '20',
    Fifty = '50',
}

export const PageSizes: SraSelectItem[] = [
    new SraSelectItem({ value: PageSize.Ten, label: PageSize.Ten }),
    new SraSelectItem({ value: PageSize.Twenty, label: PageSize.Twenty }),
    new SraSelectItem({ value: PageSize.Fifty, label: PageSize.Fifty }),
];
