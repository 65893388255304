import { Initializable } from 'src/app/common/helpers/initializable';

export class Submission extends Initializable<Submission> {
    public subject: string;
    public receiveDate: Date | string;
    public status: string;
    public submissionId: string;
    public sender: string;
    public submissionUrl: string | undefined;
    public transactionId: string | undefined;
    public transactionUrl: string | undefined;
}