import { Initializable } from 'src/app/common/helpers/initializable';

export class UiInteractStateModel extends Initializable<UiInteractStateModel> {
    public toastConfig: ToastConfig;
}

export class ToastConfig extends Initializable<ToastConfig> {
    public duration: number;
    public status: string;
    public message: string;
}
