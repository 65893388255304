import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'srdi-avatar-fallback',
    templateUrl: './avatar-fallback.component.html',
    styleUrls: ['./avatar-fallback.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarFallbackComponent {
    @Input()
    public initials: string | null;
}
