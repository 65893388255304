import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as HeaderActions from 'src/app/components/shared/components/header/store/header.actions';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HeaderState } from 'src/app/components/shared/components/header/store/header.state';

@Component({
    selector: 'srdi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    @Select(HeaderState.avatarBinary)
    public avatarBinary$: Observable<string>;

    @Select(HeaderState.userInitialsFallback)
    public userInitialsFallback$: Observable<string>;

    @Select(HeaderState.isLoading)
    public isLoading$: Observable<boolean>;

    constructor(private readonly store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new HeaderActions.LoadUserAvatarData());
    }
}
