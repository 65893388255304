import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { PageSize } from 'src/app/components/shared/components/grid/models/page-size';
import { SraSelectItem } from 'src/app/common/models/sra-select-item';

@Component({
    selector: 'srdi-custom-paging',
    templateUrl: './custom-paging.component.html',
    styleUrls: ['./custom-paging.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPagingComponent implements OnChanges {
    @Input()
    public selectedPageSize: PageSize;
    
    @Input()
    public pageSizes: SraSelectItem[];

    @Input()
    public currentPageIndex: number;

    @Input()
    public rowsCount: number;

    @Output()
    public prevPage: EventEmitter<void> = new EventEmitter();
  
    @Output()
    public nextPage: EventEmitter<void> = new EventEmitter();

    @Output()
    public changePageSize: EventEmitter<PageSize> = new EventEmitter();

    public pagingLabel: string | undefined;
    public isPrevButtonDisabled: boolean;
    public isNextButtonDisabled: boolean;

    public onPageSizeChange(event: CustomEvent): void {
        this.changePageSize.emit(event.detail);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.pagingLabel = this.setPagingLabel();
        this.setPagingValidation();
    }

    private setPagingValidation(): void {
        this.isPrevButtonDisabled = this.currentPageIndex === 0 || this.rowsCount === +this.selectedPageSize;
        const totalPages = Math.ceil(this.rowsCount / +this.selectedPageSize);
        this.isNextButtonDisabled = this.currentPageIndex + 1 === totalPages || this.rowsCount === +this.selectedPageSize;
    }

    private setPagingLabel(): string | undefined {
        const totalPages = Math.ceil(this.rowsCount / +this.selectedPageSize);
        if (totalPages === 1) {
            return `1-${this.rowsCount} of ${this.selectedPageSize}`;
        }

        const from = this.currentPageIndex * +this.selectedPageSize + 1;
        let to: number;

        if ((this.currentPageIndex === totalPages - 1) && (this.rowsCount % +this.selectedPageSize !== 0)) {
            to = this.currentPageIndex * +this.selectedPageSize + (this.rowsCount % +this.selectedPageSize);
        } else {
            to = (this.currentPageIndex + 1) * +this.selectedPageSize;
        }

        return ` ${from}-${to} of ${this.rowsCount}`;
    }
}
