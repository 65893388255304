import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DashboardState } from 'src/app/components/dashboard/store/dashboard.state';
import { DashboardTab, DashboardTabs } from 'src/app/components/dashboard/store/dashboard.state.models';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import * as DashboardActions from 'src/app/components/dashboard/store/dashboard.actions';
import { Submission } from 'src/app/components/dashboard/models/dashboard.models';

@Component({
    selector: 'srdi-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    @Select(DashboardState.activeTab)
    public activeTab$: Observable<DashboardTab>;

    @Select(DashboardState.submissions)
    public submissions$: Observable<Submission[]>;

    @Select(DashboardState.submissionsCount)
    public submissionsCount$: Observable<any>;

    @Select(DashboardState.isLoading)
    public isLoading$: Observable<boolean>;

    public tabs: DashboardTab[] = DashboardTabs;

    constructor(
        private readonly store: Store,
    ) {
    }

    public ngOnInit(): void {
        this.store.dispatch(new DashboardActions.LoadSubmissionsData());
    }

    public setActiveTab(selectedTab: DashboardTab): void {
        this.store.dispatch(new DashboardActions.SelectDashboardTab({ selectedTab }));
    }
}
