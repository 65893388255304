<sra-header>
    <sra-left-panel>
        <img src="../../../../../../../assets/images/swissre.png" width="223px" height="50px" alt="SwissRe" />
        <span class="label">Data Ingestion | Document Management</span>
    </sra-left-panel>
    <sra-right-panel>
        <div>
            <sra-icon-button category="secondary" icon="help"></sra-icon-button>
        </div>
        <div class="avatar-container" *ngIf="(isLoading$ | async) === false">
            <srdi-avatar-fallback *ngIf="(userInitialsFallback$ | async) !== undefined" [initials]="userInitialsFallback$ | async"></srdi-avatar-fallback>
            <sra-avatar *ngIf="(userInitialsFallback$ | async) === undefined" [imagePath]="avatarBinary$ | async" imageAlt="Avatar"></sra-avatar>
        </div>
    </sra-right-panel>
</sra-header>
