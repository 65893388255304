<srdi-header></srdi-header>
<div class="srdi-dashboard">
    <srdi-sub-header [label]="'Submissions'" [labelOnly]="false"></srdi-sub-header>
    <sra-tab-bar id="submission-tab">
        <sra-tab *ngFor="let tab of tabs"
                 [active]="tab === (activeTab$ | async)"
                 [label]="tab"
                 [count]="tab === 'In Progress' ? (submissionsCount$ | async)[tab] : undefined"
                 [id]="tab"
                 (click)="setActiveTab(tab)">
        </sra-tab>
    </sra-tab-bar>
    <sra-tab-panel *ngFor="let tab of tabs" [tabBarId]="tab">{{ tab }}</sra-tab-panel>
    <sra-separator></sra-separator>
    <div class="srdi-dashboard__container">
        <div class="srdi-dashboard__content">
            <div class="srdi-dashboard__content__grid">
                <srdi-grid [data]="submissions$ | async"></srdi-grid>
            </div>
        </div>
    </div>
</div>