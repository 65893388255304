import { Initializable } from 'src/app/common/helpers/initializable';
import { Enum } from 'src/app/common/helpers/enum';
import { Submission } from 'src/app/components/dashboard/models/dashboard.models';

export enum DashboardTab {
    InProgress = 'In Progress',
    Completed = 'Completed',
}

export const DashboardTabs = Enum.toArray(DashboardTab);

export class DashboardStateModel extends Initializable<DashboardStateModel> {
    public activeTab: DashboardTab = DashboardTab.InProgress;
    public submissions: Submission[] = [];
    public submissionsCount: Record<DashboardTab, number> = { 
        [DashboardTab.InProgress]: 0, 
        [DashboardTab.Completed]: 0, 
    };
    
    public isLoading: boolean = true;
}

export const submissionsDataMock = {
    submissions: [
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            status: 'Transaction completed',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 RenewalFW: Ability Matters - 1st July 2021 RenewalFW: Ability Matters - 1st July 2021 RenewalFW: Ability Matters - 1st July 2021 RenewalFW: Ability Matters - 1st July 2021 RenewalFW: Ability Matters - 1st July 2021 RenewalFW: Ability Matters - 1st July 2021 Renewal',
            sender: 'Aditya_Menon',
            received: 'Oct 17, 2023, 1:21:08 PM',
            status: 'Extraction in progress',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            received: 'Aug 30, 2023, 7:52:13 AM',
            status: 'Review required',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            received: 'Oct 3, 2023, 10:56:25 AM',
            status: 'Review completed',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            status: 'Extraction in progress',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            status: 'Extraction in progress',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'Email 1 - Microsoft Files - please ignore - DI application Postgres\n Azure DB testing',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 15, 2023, 2:03:44 PM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'Email 1 - Microsoft Files - please ignore - DI application Postgres\n Azure DB testing',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Oct 2, 2023, 8:26:22 AM',
            status: 'SUBMITTED_FOR_EXTRACTION',
            submissionId: 'PY01-2a4b18ee-7f2b-4a79-839f-4d74d453249f',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-2a4b18ee-7f2b-4a79-839f-4d74d453249f',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 11:52:57 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: DEMO 1 - Microsoft Files - please ignore - Virtual document test',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 4, 2023, 11:22:17 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Aug 30, 2023, 8:09:47 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: DEMO 1 - Microsoft Files - please ignore - Virtual document test',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 4, 2023, 11:22:17 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Proposed submission for the demo recording tomorrow',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 8:32:39 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'Email 1 - Microsoft Files - please ignore - DI application Postgres\n Azure DB testing',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 15, 2023, 2:03:44 PM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 12:00:17 PM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Proposed submission for the demo recording tomorrow',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 8:32:39 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 11:52:57 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Oct 4, 2023, 8:50:39 AM',
            status: 'SUBMITTED_FOR_EXTRACTION',
            submissionId: 'PY01-3d8a4b99-3785-4e23-9cb9-679066ef880e',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-3d8a4b99-3785-4e23-9cb9-679066ef880e',
            processed: 'Oct 4, 2023, 8:50:39 AM',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Aug 30, 2023, 1:21:08 PM',
            status: 'Extraction in progress',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            received: 'Aug 30, 2023, 7:52:13 AM',
            status: 'Review required',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            received: 'Oct 3, 2023, 10:56:25 AM',
            status: 'Review completed',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            status: 'Extraction in progress',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal.',
            sender: 'Daniel_Kovacs@swissre.com',
            status: 'Extraction in progress',
            submissionId: 'PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-6708569f-dfa9-4ec2-b81d-6e1d30d81684',
        },
        {
            emailSubject: 'Email 1 - Microsoft Files - please ignore - DI application Postgres\n Azure DB testing',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 15, 2023, 2:03:44 PM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'Email 1 - Microsoft Files - please ignore - DI application Postgres\n Azure DB testing',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Oct 2, 2023, 8:26:22 AM',
            status: 'SUBMITTED_FOR_EXTRACTION',
            submissionId: 'PY01-2a4b18ee-7f2b-4a79-839f-4d74d453249f',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-2a4b18ee-7f2b-4a79-839f-4d74d453249f',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 11:52:57 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: DEMO 1 - Microsoft Files - please ignore - Virtual document test',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 4, 2023, 11:22:17 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Ability Matters - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Aug 30, 2023, 8:09:47 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: DEMO 1 - Microsoft Files - please ignore - Virtual document test',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 4, 2023, 11:22:17 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Proposed submission for the demo recording tomorrow',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 8:32:39 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'Email 1 - Microsoft Files - please ignore - DI application Postgres\n Azure DB testing',
            sender: 'Nithyesh_CL@swissre.com',
            received: 'Sep 15, 2023, 2:03:44 PM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 12:00:17 PM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Proposed submission for the demo recording tomorrow',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 8:32:39 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Sep 7, 2023, 11:52:57 AM',
            status: 'CASE_CREATED',
        },
        {
            emailSubject: 'FW: Auto Manufacturing - 1st July 2021 Renewal',
            sender: 'Aditya_Menon@swissre.com',
            received: 'Oct 4, 2023, 8:50:39 AM',
            status: 'SUBMITTED_FOR_EXTRACTION',
            submissionId: 'PY01-3d8a4b99-3785-4e23-9cb9-679066ef880e',
            submissionUrl: 'https://swissre-preprod.meaplatform.com/QATool/PY01-3d8a4b99-3785-4e23-9cb9-679066ef880e',
            processed: 'Oct 4, 2023, 8:50:39 AM',
        },
    ],
    total: 35,
};