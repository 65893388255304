import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'srdi-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent {
    @Input()
    public label: string;

    @Input()
    public labelOnly: boolean = true;
}
