import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HeaderStateModel } from 'src/app/components/shared/components/header/store/header.state.models';
import * as HeaderActions from 'src/app/components/shared/components/header/store/header.actions';
import { UserDataService } from 'src/app/common/services/user-data.service';

@State<HeaderStateModel>({
    name: 'header',
    defaults: new HeaderStateModel(),
})
@Injectable()
export class HeaderState {
    constructor(
        private readonly store: Store,
        private readonly userDataService: UserDataService,
    ) { }

    @Selector()
    public static avatarBinary(state: HeaderStateModel): string {
        return state.avatarBinary;
    }

    @Selector()
    public static userInitialsFallback(state: HeaderStateModel): string | null {
        return state.userInitialsFallback;
    }

    @Selector()
    public static isLoading(state: HeaderStateModel): boolean {
        return state.isLoading;
    }

    @Action(HeaderActions.LoadUserAvatarData)
    public loadUserAvatarData(ctx: StateContext<HeaderStateModel>): void {
        const avatarBinary = this.store.selectSnapshot(HeaderState.avatarBinary);

        if (avatarBinary) {
            return;
        }

        ctx.patchState({ isLoading: true });

        this.userDataService.getUserAvatar().subscribe(
            (avatarBinary: string) => ctx.dispatch(new HeaderActions.LoadUserAvatarDataSuccess({ avatarBinary })),
            (error: any) => ctx.dispatch(new HeaderActions.LoadUserAvatarDataError({ error })),
        );
    }

    @Action(HeaderActions.LoadUserAvatarDataError)
    public loadUserAvatarDataError(ctx: StateContext<HeaderStateModel>): void {
        ctx.dispatch(new HeaderActions.LoadUserInitialsData());
    }

    @Action(HeaderActions.LoadUserAvatarDataSuccess)
    public loadUserAvatarDataSuccess(
        ctx: StateContext<HeaderStateModel>,
        { avatarBinary }: HeaderActions.LoadUserAvatarDataSuccess,
    ): void {
        ctx.patchState({ avatarBinary, isLoading: false });
    }
    
    @Action(HeaderActions.LoadUserInitialsData)
    public loadUserInitialsData(ctx: StateContext<HeaderStateModel>): void {
        this.userDataService.getUserInfo().subscribe(            
            (userInitialsFallback: string) => ctx.dispatch(new HeaderActions.LoadUserInitialsDataSuccess({ userInitialsFallback })),
            (error: any) => ctx.dispatch(new HeaderActions.LoadUserInitialsDataError({ error })),
        );
    }
    
    @Action(HeaderActions.LoadUserInitialsDataSuccess)
    public loadUserInitialsDataSuccess(
        ctx: StateContext<HeaderStateModel>,
        { userInitialsFallback }: HeaderActions.LoadUserInitialsDataSuccess,
    ): void {
        ctx.patchState({ userInitialsFallback, isLoading: false });
    }

    @Action(HeaderActions.LoadUserInitialsDataError)
    public loadUserInitialsDataError(
        ctx: StateContext<HeaderStateModel>,
        { error }: HeaderActions.LoadUserInitialsDataError,
    ): void {
        ctx.patchState({ isLoading: false });
    }
}