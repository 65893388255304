import { DashboardStateModel, DashboardTab } from 'src/app/components/dashboard/store/dashboard.state.models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import * as DashboardActions from 'src/app/components/dashboard/store/dashboard.actions';
import * as UiInteractActions from 'src/app/components/ui-interact/store/ui-interact.actions';
import { Router } from '@angular/router';
import { Submission } from 'src/app/components/dashboard/models/dashboard.models';
import { SubmissionsDataService } from 'src/app/components/dashboard/services/submissions-data.service';
import { ToastConfig } from 'src/app/components/ui-interact/store/ui-interact.state.models';

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: new DashboardStateModel(),
})
@Injectable()
export class DashboardState {
    constructor(
        private readonly router: Router,
        private readonly submissionsDataService: SubmissionsDataService,
    ) {}

    @Selector()
    public static activeTab(state: DashboardStateModel): DashboardTab {
        return state.activeTab;
    }

    @Selector()
    public static submissions(state: DashboardStateModel): Submission[] {
        return state.submissions;
    }

    @Selector()
    public static isLoading(state: DashboardStateModel): boolean {
        return state.isLoading;
    }
    
    @Selector()
    public static submissionsCount(state: DashboardStateModel): any {
        return state.submissionsCount;
    }

    @Action(DashboardActions.SelectDashboardTab)
    public selectDashboardTab(
        ctx: StateContext<DashboardStateModel>,
        { selectedTab }: DashboardActions.SelectDashboardTab,
    ): void {
        ctx.patchState({ activeTab: selectedTab });
        this.submissionsDataService.activeTab = selectedTab;
        this.submissionsDataService.pageIndex = 0;
        ctx.dispatch(new DashboardActions.LoadSubmissionsData());
    }

    @Action(DashboardActions.LoadSubmissionsData)
    public loadSubmissionsData(ctx: StateContext<DashboardStateModel>): void {
        ctx.patchState({ isLoading: true });

        this.submissionsDataService.getSubmissionsData().subscribe(
            (response: any) => {
                const submissionsUpdates = { submissions: response.submissions, submissionsCount: response.total };
                ctx.dispatch(new DashboardActions.LoadSubmissionsDataSuccess(submissionsUpdates));
            },
            (error: any) => ctx.dispatch(new DashboardActions.LoadSubmissionsDataError({ error })),
        );
    }

    @Action(DashboardActions.LoadSubmissionsDataError)
    public loadSubmissionsDataError(
        ctx: StateContext<DashboardStateModel>,
        { error }: DashboardActions.LoadSubmissionsDataError,
    ): void {
        const toastConfig = new ToastConfig({ duration: 8000, status: 'error', message: 'Error loading submissions data' });

        ctx.dispatch(new UiInteractActions.SetToastConfig({ toastConfig }));
        ctx.patchState({ submissions: [], isLoading: false });
    }

    @Action(DashboardActions.LoadSubmissionsDataSuccess)
    public loadSubmissionDataSuccess(
        ctx: StateContext<DashboardStateModel>,
        { submissions, submissionsCount }: DashboardActions.LoadSubmissionsDataSuccess,
    ): void {
        const submissionsCountUpdates = { ...ctx.getState().submissionsCount, [ctx.getState().activeTab]: submissionsCount};
        ctx.patchState({ submissions, isLoading: false,  submissionsCount: submissionsCountUpdates });
    }
}