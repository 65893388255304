<div class="srdi-grid">
    <srdi-custom-paging
            [pageSizes]="pageSizes"
            [selectedPageSize]="pageSize"
            [currentPageIndex]="currentPageIndex"
            [rowsCount]="rowsCount"
            (prevPage)="handlePrevPage()"
            (nextPage)="handleNextPage()"
            (changePageSize)="handleChangePageSize($event)"
    ></srdi-custom-paging>
    <dx-data-grid #grid id="dataGrid"
                  [dataSource]="data"
                  [height]="(isLoading$ | async) || !data?.length ? 500 : 'calc(100vh - 260px)'"
                  [noDataText]="'No Data Available'"
                  [columnAutoWidth]="true"
                  [width]="'100%'"
                  [scrolling]="{ mode: 'standard' }"
                  keyExpr="submissionId"
                  (onOptionChanged)="optionChangedHandler($event)"
    >
        <dxo-search-panel [visible]="true" [placeholder]="'Search by email subject'"></dxo-search-panel>
        <dxo-paging [pageSize]="+pageSize">
        </dxo-paging>
        <dxo-pager
                [visible]="false"
        ></dxo-pager>
        <dxo-sorting mode="single"></dxo-sorting>
        <dxi-column dataField="subject" caption="Email subject" dataType="string" [width]="'582px'"></dxi-column>
        <dxi-column dataField="sender" caption="Sender" dataType="string" [width]="'auto'"></dxi-column>
        <dxi-column dataField="receiveDate"
                    caption="Extraction date"
                    dataType="date"
                    format="dd MMM yyyy"
                    [alignment]="'right'"
                    [width]="140"
                    [sortIndex]="0"
                    [sortOrder]="'desc'">
        </dxi-column>
        <dxi-column dataField="status" dataType="string" caption="Status" [width]="'auto'"></dxi-column>
        <dxi-column dataField="submissionId" dataType="string" caption="Submission ID" cellTemplate="submissionUrlTemplate" [width]="'auto'"></dxi-column>
        <dxi-column *ngIf="(activeTab$ | async) === 'Completed'" dataField="transactionId" dataType="string" caption="Transaction ID" cellTemplate="transactionUrlTemplate" [width]="'auto'"></dxi-column>
        <ng-container *dxTemplate="let cell of 'submissionUrlTemplate'">
            <ng-container *ngTemplateOutlet="cell.data.submissionUrl && cell.data.submissionId !== '-' ? submissionUrl : emptySubmissionUrl; context: { data: cell }"></ng-container>
        </ng-container>
        <ng-container *dxTemplate="let cell of 'transactionUrlTemplate'">
            <ng-container *ngTemplateOutlet="cell.data.transactionUrl && cell.data.transactionId !== '-' ? transactionUrl : emptyTransactionUrl; context: { data: cell }"></ng-container>
        </ng-container>
    </dx-data-grid>
    <srdi-loader *ngIf="isLoading$ | async"></srdi-loader>
</div>
<!-- ToDo: Remove copypaste for mea/uwb links -->
<ng-template #submissionUrl let-cell="data">
    <a [href]="cell.data.submissionUrl" [title]="cell.data.submissionId" target="_blank" rel="noreferrer noopener">{{ cell.value }}</a>
</ng-template>
<ng-template #emptySubmissionUrl let-cell="data"><div class="submissionId" [title]="cell.data.submissionId">{{ cell.data.submissionId }}</div></ng-template>

<ng-template #transactionUrl let-cell="data">
    <a [href]="cell.data.transactionUrl" [title]="cell.data.transactionId" target="_blank" rel="noreferrer noopener">{{ cell.value }}</a>
</ng-template>
<ng-template #emptyTransactionUrl let-cell="data"><div class="transactionId" [title]="cell.data.transactionId">{{ cell.data.transactionId }}</div></ng-template>
