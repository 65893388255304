import { Initializable } from 'src/app/common/helpers/initializable';

const source = '[HeaderState]';

export class LoadUserAvatarData extends Initializable<LoadUserAvatarData> {
    public static type: string = `${source} load user avatar data`;
}

export class LoadUserAvatarDataSuccess extends Initializable<LoadUserAvatarDataSuccess> {
    public static type: string = `${source} load user avatar data success`;

    public avatarBinary: string;
}

export class LoadUserAvatarDataError extends Initializable<LoadUserAvatarDataError> {
    public static type: string = `${source} load user avatar data error`;

    public error: any;
}

export class LoadUserInitialsData extends Initializable<LoadUserInitialsData> {
    public static type: string = `${source} load user initials data`;
}

export class LoadUserInitialsDataSuccess extends Initializable<LoadUserInitialsDataSuccess> {
    public static type: string = `${source} load user initials data success`;

    public userInitialsFallback: string;
}

export class LoadUserInitialsDataError extends Initializable<LoadUserInitialsDataError> {
    public static type: string = `${source} load user initials data error`;

    public error: any;
}